const typography = {
  body1: {
    fontWeight: 400,
    letterSpacing: 0.44
  },
  overline: {
    letterSpacing: 1.5,
    fontSize: 10
  }
}

export default typography
